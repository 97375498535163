<template>
    <div class="affiliate-commisions">
        <list :apiList="apiList" :fs="fs" :showDelete="false" title="Commisions">
            <template slot="list-bottom" slot-scope="{ items }">
                <md-table-header>
                    <md-table-row>
                        <md-table-head>Total Commisions:{{ (getPaidTotal(items) * 0.1) | currency }}</md-table-head>
                        <md-table-head>Paid Commisions:{{ user.affiliatePaidCommision || 0 | currency }}</md-table-head>
                        <md-table-head></md-table-head>
                        <md-table-head></md-table-head>
                        <md-table-head></md-table-head>
                        <md-table-head></md-table-head>
                        <md-table-head></md-table-head>
                        <md-table-head></md-table-head>
                        <md-table-head></md-table-head>
                        <md-table-head></md-table-head>
                    </md-table-row>
                </md-table-header>
            </template>
        </list>
    </div>
</template>
<script>
// this file is not used
import List from "@/components/List";
import { mapGetters } from "vuex";

export default {
    components: {
        List
    },
    computed: {
        ...mapGetters({
            user: "user/user"
        }),
        fs() {
            return [
                //
                { heading: "Order No", data: "orderNo", type: "text" },
                { heading: "Order Total", data: "amount.total", type: "currency" },
                { heading: "Order Shipping", data: "amount.shipping", type: "currency" },
                { heading: "Order Security Deposit", data: "amount.securityDeposit", type: "currency" },
                { heading: "Order Deposit", data: "amount.deposit", type: "currency" },
                { heading: "Order Paid", data: "paid", type: "currency" },
                { heading: "Status", data: "status" },
                { heading: "Payment Status", data: "paymentStatus" },
                { heading: "Created At", data: "createdAt", type: "datetime" },
                { heading: "Updated At", data: "updatedAt", type: "datetime" }
            ];
        },
        apiList() {
            return "affiliate/getCommisions";
        },
        getPaidTotal() {
            return items => items.reduce((acc, item) => acc + item.paid, 0);
        }
    }
};
</script>
